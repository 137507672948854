import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import {
  ApiResponse,
  Flow,
  FlowTemplate,
  HttpBaseRequest,
  QuestionStyles,
  SaveFlowTemplateRequest,
  SimpleFlow,
  SimpleFlowQuestion,
  SimpleFlowQuestionOption,
} from '@vision/ui/interfaces';
import { buildEndpointWithQuery, createHttpExtraOptions, insertIfObject } from '@vision/ui/utils';
import { axiosBaseQuery } from './base.service';

export const FlowsService = createApi({
  reducerPath: 'FlowsService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getFlows: builder.query<
      Flow[],
      HttpBaseRequest<{
        nodeId: string;
        includeQuestionOptions?: boolean;
        includeQuestions?: boolean;
        allowSavingIncompleteSurvey?: boolean;
      }>
    >({
      query: ({ nodeId, includeQuestionOptions, includeQuestions, allowSavingIncompleteSurvey }) => ({
        url: '/v1/flows',
        method: HttpMethod.GET,
        params: {
          node_id: nodeId,
          include_questions: includeQuestions,
          include_options: includeQuestionOptions,
          allow_saving_incomplete_survey: allowSavingIncompleteSurvey,
        },
      }),
    }),
    saveFlowTemplate: builder.mutation<FlowTemplate, HttpBaseRequest<SaveFlowTemplateRequest>>({
      query: (data) => ({
        url: '/v1/flow_templates',
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
          },
        }),
      }),
    }),
    updateFlowTemplate: builder.mutation<FlowTemplate, HttpBaseRequest<FlowTemplate>>({
      query: (data) => ({
        url: `/v1/flow_templates/${data.id}/update`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
          },
        }),
      }),
    }),
    fetchFlowsWithPagination: builder.query<
      ApiResponse<SimpleFlow[]>,
      {
        nodeId: string;
        query?: string;
        includeQuestions?: boolean;
        questionStyles?: QuestionStyles[];
        questionCategoryNotNull?: boolean;
        questionKeyNotNull?: boolean;
      }
    >({
      query: ({ nodeId, query, includeQuestions, questionStyles, questionCategoryNotNull, questionKeyNotNull }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/flows`, query),
        method: HttpMethod.GET,
        params: {
          ...insertIfObject(!!includeQuestions, {
            include_questions: includeQuestions,
          }),
          ...insertIfObject(!!questionStyles && !!questionStyles.length, {
            question_styles: questionStyles?.join(','),
          }),
          ...insertIfObject(!!questionCategoryNotNull, {
            question_category_not_null: questionCategoryNotNull,
          }),
          ...insertIfObject(!!questionKeyNotNull, {
            question_key_not_null: questionKeyNotNull,
          }),
        },
      }),
    }),
    fetchFlowQuestionsWithPagination: builder.query<
      ApiResponse<SimpleFlowQuestion[]>,
      {
        nodeId: string;
        flowIds?: string[];
        style?: QuestionStyles[];
        query?: string;
        keyNotNull?: boolean;
        categoryNotNull?: boolean;
      }
    >({
      query: ({ nodeId, flowIds, style, query, keyNotNull, categoryNotNull }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/flows/questions`, query),
        method: HttpMethod.GET,
        params: {
          ...insertIfObject(!!flowIds && !!flowIds.length, {
            flow_ids: flowIds?.join(','),
          }),
          ...insertIfObject(!!style && !!style.length, {
            style: style?.join(','),
          }),
          ...insertIfObject(!!keyNotNull, {
            key_not_null: keyNotNull,
          }),
          ...insertIfObject(!!categoryNotNull, {
            category_not_null: categoryNotNull,
          }),
        },
      }),
    }),
    fetchFlowQuestionOptionsWithPagination: builder.query<
      ApiResponse<SimpleFlowQuestionOption[]>,
      { nodeId: string; questionIds?: string[]; query?: string; flowIds?: string[] }
    >({
      query: ({ nodeId, questionIds, query, flowIds }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/flows/options`, query),
        method: HttpMethod.GET,
        params: {
          ...insertIfObject(!!questionIds && !!questionIds.length, {
            question_ids: questionIds?.join(','),
          }),
          ...insertIfObject(!!flowIds && !!flowIds.length, {
            flow_ids: flowIds?.join(','),
          }),
        },
      }),
    }),
  }),
});

export const {
  useGetFlowsQuery,
  useLazyGetFlowsQuery,
  useSaveFlowTemplateMutation,
  useUpdateFlowTemplateMutation,
  useLazyFetchFlowsWithPaginationQuery,
  useLazyFetchFlowQuestionsWithPaginationQuery,
  useLazyFetchFlowQuestionOptionsWithPaginationQuery,
} = FlowsService;
