import { If, VLazyLoadMultiSelect, VSelectOptionWithDescription } from '@vision/ui/components';
import { useEmojiContext, useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues, SimpleFlowQuestion } from '@vision/ui/interfaces';
import {
  useLazyFetchFlowQuestionsWithPaginationQuery,
  useLazyFetchFlowsWithPaginationQuery,
} from '@vision/ui/services';
import { getBodyText, insertIfObject, translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

interface ReportsDetailGraphicCompareQuestionKeyProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareQuestionKey({
  compareItemType,
  required,
}: ReportsDetailGraphicCompareQuestionKeyProps) {
  const { t, i18n } = useTranslation();
  const accountId = useSelectedAccountId();
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();
  const { textWithNativeEmoji } = useEmojiContext();

  const handleDefaultValuesLoaded = (data: SimpleFlowQuestion[]) => {
    // data içindeki idleri formik.values.selectedCompareCriteria.selectedQuestionIds içinde varsa onu sil
    const newIds = formik.values.selectedCompareCriteria.selectedQuestionKeyIds.filter((id) =>
      data.some((item) => item.id === id),
    );
    formik.setFieldValue('selectedCompareCriteria.selectedQuestionKeyIds', newIds);
  };

  const mapItemToSelectOption = (item: SimpleFlowQuestion) => ({
    value: item.id,
    label: item.key,
    description: `${textWithNativeEmoji(getBodyText(item.body, i18n.language))}`,
    ...insertIfObject(!!item?.flow_name, {
      title: `${textWithNativeEmoji(getBodyText(item.body, i18n.language))}\n\n${
        item?.flow_name && `${t('flowName')}: ${item?.flow_name}`
      }`,
    }),
  });

  return (
    <ReportsDetailGraphicCompareLayout title={t(`page-reports:reportCompareGroupItemType.${compareItemType}`)}>
      <VLazyLoadMultiSelect
        id="report-compare-question-key-flow"
        data-testid="input-report-compare-question-key-flow"
        label={t('flows')}
        useLazyApiQueryFunction={useLazyFetchFlowsWithPaginationQuery}
        apiRequestParameters={{ nodeId: accountId, questionKeyNotNull: true }}
        multiSelectItemMapper={(items) => {
          return items.map((item) => ({
            value: item.id,
            label: item.name,
          }));
        }}
        searchKey="flow_name"
        value={formik.values.selectedCompareCriteria.selectedQuestionKeyFlowIds}
        searchable={true}
        clearable={true}
        onBlur={formik.handleBlur}
        onChange={(values) => formik.setFieldValue('selectedCompareCriteria.selectedQuestionKeyFlowIds', values)}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedQuestionKeyFlowIds')}
        withAsterisk={required}
        itemComponent={VSelectOptionWithDescription}
      />

      <If value={formik.values.selectedCompareCriteria.selectedQuestionKeyFlowIds.length > 0}>
        <VLazyLoadMultiSelect
          id="report-compare-flow-question-key"
          data-testid="input-report-compare-flow-question-key"
          label={t('question')}
          useLazyApiQueryFunction={useLazyFetchFlowQuestionsWithPaginationQuery}
          apiRequestParameters={{
            nodeId: accountId,
            flowIds: formik.values.selectedCompareCriteria.selectedQuestionKeyFlowIds,
            keyNotNull: true,
          }}
          multiSelectItemMapper={(items) => items.filter((item) => !!item.key).map(mapItemToSelectOption)}
          searchKey="key"
          searchable={true}
          value={formik.values.selectedCompareCriteria.selectedQuestionKeyIds}
          onBlur={formik.handleBlur}
          onChange={(e) => formik.setFieldValue('selectedCompareCriteria.selectedQuestionKeyIds', e)}
          error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedQuestionKeyIds')}
          resetChangeRequestParameters={true}
          onDefaultValuesLoaded={handleDefaultValuesLoaded}
          itemComponent={VSelectOptionWithDescription}
        />
      </If>
    </ReportsDetailGraphicCompareLayout>
  );
}
