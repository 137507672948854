import { VLazyLoadMultiSelect } from '@vision/ui/components';
import { useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { useLazyFetchFlowsWithPaginationQuery } from '@vision/ui/services';
import { translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

interface ReportsDetailGraphicCompareFlowProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareFlow({ required }: ReportsDetailGraphicCompareFlowProps) {
  const { t } = useTranslation();
  const accountId = useSelectedAccountId();

  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();

  return (
    <ReportsDetailGraphicCompareLayout title={t('flows')}>
      <VLazyLoadMultiSelect
        id="report-compare-flows"
        data-testid="input-report-compare-flows"
        label={t('flows')}
        useLazyApiQueryFunction={useLazyFetchFlowsWithPaginationQuery}
        apiRequestParameters={{ nodeId: accountId }}
        multiSelectItemMapper={(items) => {
          return items.map((item) => ({
            value: item.id,
            label: item.name,
          }));
        }}
        searchKey="flow_name"
        value={formik.values.selectedCompareCriteria.selectedFlowIds}
        searchable={true}
        clearable={true}
        onBlur={formik.handleBlur}
        onChange={(e) => formik.setFieldValue('selectedCompareCriteria.selectedFlowIds', e)}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedFlowIds')}
        withAsterisk={required}
        withSelectAll={false}
      />
    </ReportsDetailGraphicCompareLayout>
  );
}
