import { If, VLazyLoadMultiSelect, VLazyLoadSelect, VSelectOptionWithDescription } from '@vision/ui/components';
import { useEmojiContext, useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues, SimpleFlowQuestion } from '@vision/ui/interfaces';
import {
  useLazyFetchFlowQuestionsWithPaginationQuery,
  useLazyFetchFlowsWithPaginationQuery,
} from '@vision/ui/services';
import { getBodyText, insertIfObject, translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

interface ReportsDetailGraphicCompareQuestionCategoryProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareQuestionCategory({
  compareItemType,
  required,
}: ReportsDetailGraphicCompareQuestionCategoryProps) {
  const { t, i18n } = useTranslation(['translation', 'page-reports']);
  const accountId = useSelectedAccountId();
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();
  const { textWithNativeEmoji } = useEmojiContext();

  const isFlowSelected = formik.values.selectedCompareCriteria.selectedQuestionCategoryFlowIds.length > 0;

  const handleDefaultValuesLoaded = (data: SimpleFlowQuestion[]) => {
    // data içindeki idleri formik.values.selectedCompareCriteria.selectedQuestionIds içinde varsa onu sil
    const newIds = formik.values.selectedCompareCriteria.selectedQuestionCategoryFlowIds.filter((id) =>
      data.some((item) => item.id === id),
    );
    formik.setFieldValue('selectedCompareCriteria.selectedQuestionCategoryFlowIds', newIds);
  };

  const mapItemToSelectOption = (item: SimpleFlowQuestion) => ({
    value: item.category?.id,
    label: item.category?.name,
    description: `${textWithNativeEmoji(getBodyText(item.body, i18n.language))}`,
    ...insertIfObject(!!item?.flow_name, {
      title: `${textWithNativeEmoji(getBodyText(item.body, i18n.language))}\n\n${
        item?.flow_name && `${t('flowName')}: ${item?.flow_name}`
      }`,
    }),
  });

  return (
    <ReportsDetailGraphicCompareLayout title={t(`page-reports:reportCompareGroupItemType.${compareItemType}`)}>
      <VLazyLoadSelect
        id="report-compare-question-category-flow"
        data-testid="input-report-compare-question-category-flow"
        label={t('flows')}
        useLazyApiQueryFunction={useLazyFetchFlowsWithPaginationQuery}
        apiRequestParameters={{
          nodeId: accountId,
          questionCategoryNotNull: true,
        }}
        multiSelectItemMapper={(items) => {
          return items.map((item) => ({
            value: item.id,
            label: item.name,
          }));
        }}
        searchKey="flow_name"
        value={formik.values.selectedCompareCriteria.selectedQuestionCategoryFlowIds[0]}
        onChange={(value) => formik.setFieldValue('selectedCompareCriteria.selectedQuestionCategoryFlowIds', [value])}
        onBlur={formik.handleBlur}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedQuestionCategoryFlowIds')}
        withAsterisk={required}
        itemComponent={VSelectOptionWithDescription}
      />

      <If value={isFlowSelected}>
        <VLazyLoadMultiSelect
          id="report-compare-flow-question-category-name"
          data-testid="input-report-compare-flow-question-category-name"
          label={t('question-category')}
          useLazyApiQueryFunction={useLazyFetchFlowQuestionsWithPaginationQuery}
          apiRequestParameters={{
            nodeId: accountId,
            flowIds: formik.values.selectedCompareCriteria.selectedQuestionCategoryFlowIds,
            categoryNotNull: true,
          }}
          multiSelectItemMapper={(items) => items.filter((item) => !!item.category).map(mapItemToSelectOption)}
          searchKey="category_name"
          value={formik.values.selectedCompareCriteria.selectedQuestionCategoryIds}
          onBlur={formik.handleBlur}
          onChange={(value) => formik.setFieldValue('selectedCompareCriteria.selectedQuestionCategoryIds', value)}
          error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedQuestionCategoryIds')}
          withAsterisk={required}
          resetChangeRequestParameters={true}
          onDefaultValuesLoaded={handleDefaultValuesLoaded}
          itemComponent={VSelectOptionWithDescription}
        />
      </If>
    </ReportsDetailGraphicCompareLayout>
  );
}
