import { If, VLazyLoadMultiSelect, VMultiSelect, VSelectOptionWithDescription } from '@vision/ui/components';
import { ChannelType } from '@vision/ui/enums';
import { useChannelTypes, useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues, SimpleChannel } from '@vision/ui/interfaces';
import { useLazyFetchChannelsWithPaginationQuery } from '@vision/ui/services';
import { translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

interface ReportsDetailGraphicCompareChannelTypeProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareChannelType({
  compareItemType,
  required,
}: ReportsDetailGraphicCompareChannelTypeProps) {
  const { t } = useTranslation();
  const accountId = useSelectedAccountId();
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();
  const channelTypes = useChannelTypes();
  const isSelectedChannelTypes = formik.values.selectedCompareCriteria.selectedChannelTypes.length > 0;

  const channelTypeSelectItems = useMemo(
    () => channelTypes.filter((item) => item.value !== ChannelType.KIOSK),
    [channelTypes],
  );

  const handleDefaultValuesLoaded = (data: SimpleChannel[]) => {
    const newIds = formik.values.selectedCompareCriteria.selectedChannelIds.filter((id) =>
      data.some((item) => item.id === id),
    );
    formik.setFieldValue('selectedCompareCriteria.selectedChannelIds', newIds);
  };

  const handleOnChangedChannelTypes = (values: string[]) => {
    formik.setFieldValue('selectedCompareCriteria', {
      ...formik.values.selectedCompareCriteria,
      selectedChannelTypes: values,
      selectedChannelIds: values.length ? formik.values.selectedCompareCriteria.selectedChannelIds : [],
    });
  };

  return (
    <ReportsDetailGraphicCompareLayout title={t(`page-reports:reportCompareGroupItemType.${compareItemType}`)}>
      <VMultiSelect
        id="report-compare-channel-type"
        data-testid="input-report-compare-channel-type"
        label={t('channelType')}
        data={channelTypeSelectItems}
        value={formik.values.selectedCompareCriteria.selectedChannelTypes}
        searchable={true}
        clearable={true}
        placeholder={t('search')}
        onBlur={formik.handleBlur}
        onChange={handleOnChangedChannelTypes}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedChannelTypes')}
        withAsterisk={required}
        withSelectAll={false}
      />

      <If value={isSelectedChannelTypes}>
        <VLazyLoadMultiSelect
          id="report-compare-channels"
          data-testid="input-report-compare-channels"
          label={t('page-reports:channels')}
          useLazyApiQueryFunction={useLazyFetchChannelsWithPaginationQuery}
          apiRequestParameters={{
            nodeId: accountId,
            types: formik.values.selectedCompareCriteria.selectedChannelTypes,
          }}
          multiSelectItemMapper={(items) => {
            return items.map((item) => ({
              value: item.id,
              label: item.name,
              description: item.type,
            }));
          }}
          searchKey="node_name"
          value={formik.values.selectedCompareCriteria.selectedChannelIds}
          onBlur={formik.handleBlur}
          onChange={(e) => formik.setFieldValue('selectedCompareCriteria.selectedChannelIds', e)}
          error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedChannelIds')}
          resetChangeRequestParameters={true}
          withAsterisk={required}
          onDefaultValuesLoaded={handleDefaultValuesLoaded}
          clearable={true}
          itemComponent={VSelectOptionWithDescription}
        />
      </If>
    </ReportsDetailGraphicCompareLayout>
  );
}
