import { VLazyLoadMultiSelect } from '@vision/ui/components';
import { useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { useLazyFetchUsersWithPaginationQuery } from '@vision/ui/services';
import { translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

interface ReportsDetailGraphicCompareUserProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareUser({ compareItemType, required }: ReportsDetailGraphicCompareUserProps) {
  const { t } = useTranslation();
  const accountId = useSelectedAccountId();
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();

  const formikKey = useMemo(() => {
    if (compareItemType === 'user-assigned') {
      return 'selectedUserAssignedIds';
    } else if (compareItemType === 'user-archiving') {
      return 'selectedUserArchivingIds';
    } else if (compareItemType === 'user-responsible') {
      return 'selectedUserResponsibleIds';
    } else return 'selectedUserIds';
  }, [compareItemType]);

  return (
    <ReportsDetailGraphicCompareLayout title={t(`page-reports:reportCompareGroupItemType.${compareItemType}`)}>
      <VLazyLoadMultiSelect
        id="report-compare-users"
        data-testid="input-report-compare-users"
        label={t('users')}
        useLazyApiQueryFunction={useLazyFetchUsersWithPaginationQuery}
        apiRequestParameters={{ nodeId: accountId, view: 'simple' }}
        value={formik.values.selectedCompareCriteria[formikKey]}
        multiSelectItemMapper={(items) =>
          items.map((item) => ({
            value: item.id,
            label: item.full_name || item.email,
          }))
        }
        searchKey="user_name"
        onChange={(e) => {
          formik.setFieldValue(`selectedCompareCriteria.${formikKey}`, e);
        }}
        error={translateErrorMessage(formik, `selectedCompareCriteria.${formikKey}`)}
        withAsterisk={required}
      />
    </ReportsDetailGraphicCompareLayout>
  );
}
