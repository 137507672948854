import { createApi } from '@reduxjs/toolkit/query/react';
import { ChannelType, HttpMethod } from '@vision/ui/enums';
import { ApiResponse, HttpBaseRequest, INode, SimpleChannel, SimpleNode, User } from '@vision/ui/interfaces';
import { buildEndpointWithQuery, insertIfObject } from '@vision/ui/utils';
import { axiosBaseQuery } from './base.service';

export const NodesService = createApi({
  reducerPath: 'NodesService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getNodeChildren: builder.query<
      INode[],
      HttpBaseRequest<{ nodeId: string; lightweight?: boolean; includeSelf?: boolean; type?: string }>
    >({
      query: ({ nodeId, lightweight, includeSelf, type }) => ({
        url: `/v1/nodes/${nodeId}/children`,
        method: HttpMethod.GET,
        params: {
          lightweight,
          include_self: includeSelf,
          type,
        },
      }),
    }),
    nodeUsers: builder.query<User[], { nodeId: string }>({
      query: ({ nodeId }) => ({
        url: `/v1/nodes/${nodeId}/users`,
        method: HttpMethod.GET,
      }),
    }),
    nodeDetail: builder.query<INode, { nodeId: string }>({
      query: ({ nodeId }) => ({
        url: `/v1/nodes/${nodeId}/detail`,
        method: HttpMethod.GET,
      }),
    }),
    fetchChannelsWithPagination: builder.query<
      ApiResponse<SimpleChannel[]>,
      { nodeId: string; query?: string; types?: ChannelType[] }
    >({
      query: ({ nodeId, query, types }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/dashboards/nodes`, query),
        method: HttpMethod.GET,
        params: {
          ...insertIfObject(!!types && !!types.length, {
            types: types.join(','),
          }),
        },
      }),
    }),
    getNodeTypes: builder.query<string[], { nodeId: string; types: string[] }>({
      query: ({ nodeId, types }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/node_types`,
        method: HttpMethod.GET,
        params: {
          ...insertIfObject(!!types && !!types.length, {
            types: types?.join(','),
          }),
        },
      }),
    }),
    fetchNodesWithPagination: builder.query<
      ApiResponse<SimpleNode[]>,
      { nodeId: string; query?: string; types: string[]; includeSelf?: boolean }
    >({
      query: ({ nodeId, query, types, includeSelf = false }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/dashboards/nodes`, query),
        method: HttpMethod.GET,
        params: {
          ...insertIfObject(!!types && !!types.length, {
            types: types?.join(','),
          }),
          ...insertIfObject(!!includeSelf, {
            include_self: includeSelf,
          }),
        },
      }),
    }),
  }),
});

export const {
  useGetNodeChildrenQuery,
  useNodeUsersQuery,
  useNodeDetailQuery,
  useGetNodeTypesQuery,
  useLazyFetchChannelsWithPaginationQuery,
  useLazyFetchNodesWithPaginationQuery,
} = NodesService;
